import { capitalize } from 'util/strings'
import {
  selectIsOnReportsMainPage,
  selectIsOnOauthErrorPage,
  selectIsOnLoginPage,
  selectIsOnAIAgentPage,
} from 'selectors/app'
import {
  selectIsOnKbCreatePage,
  selectIsOnKbDashboardPage,
  selectIsOnKbThemePage,
  selectIsOnKbArticlesPage,
  selectIsOnKbArticleCreatePage,
  selectIsOnKbArticleEditPage,
  selectIsOnKbCategoriesPage,
  selectIsOnKbCategoryCreatePage,
  selectIsOnKbCategoryEditPage,
  selectIsOnKbSettingsPage,
} from 'subapps/kb/selectors/pages'
import { selectCurrentArticlesState } from 'subapps/kb/selectors/articles'
import { selectCurrentCategoriesState } from 'subapps/kb/selectors/categories'
import { selectCurrentTicketTitle } from 'selectors/tickets'
import { selectCurrentFolderName } from 'selectors/folders'
import {
  selectIsCreatingNewEmail,
  selectIsLoggingNewConversation,
} from 'selectors/page'
import { selectCurrentPath, selectIsInInbox } from 'selectors/location'
import {
  selectCurrentTicketSearchQueryTitle,
  selectCurrentTicketSearchQueryString,
  selectCurrentTicketSearchResultTotalCount,
} from 'selectors/search'

// Note: Changed to selectors directly to prevent circular references
import { selectIsOnAgentsOverviewPage } from 'ducks/reports/agents/selectors'
import { selectIsOnConversationsPage } from 'ducks/reports/conversations/selectors'
import { selectIsOnProductivityPage } from 'ducks/reports/productivity/selectors'
import { selectIsOnHappinessPage } from 'ducks/reports/happiness/selectors'
import { selectIsOnArticlesPage } from 'ducks/reports/kb/selectors'
import {
  selectIsOnAgentOverviewPage,
  selectIsOnTeamOverviewPage,
  selectAgentOverviewName,
  selectTeamOverviewName,
} from 'ducks/reports/detail/selectors'
import { selectIsOnTeamsOverviewPage } from 'ducks/reports/teams/selectors'
import { selectIsOnOnboardingPage } from 'subapps/onboarding/selectors'
import { selectShowInboxesEmptyPage } from 'selectors/trial_setup'

export const selectGlobalTitle = state => {
  const onNewEmailPage = selectIsCreatingNewEmail(state)
  const onNewConversationPage = selectIsLoggingNewConversation(state)
  const onReportsMainPage = selectIsOnReportsMainPage(state)
  const onKbCreatePage = selectIsOnKbCreatePage(state)
  const onKbDashboardPage = selectIsOnKbDashboardPage(state)
  const onKbThemePage = selectIsOnKbThemePage(state)
  const articlesState = selectCurrentArticlesState(state)
  const onKbArticlesPage = selectIsOnKbArticlesPage(state)
  const onKbArticleCreatePage = selectIsOnKbArticleCreatePage(state)
  const onKbArticleEditPage = selectIsOnKbArticleEditPage(state)
  const categoriesState = selectCurrentCategoriesState(state)
  const onKbCategoriesPage = selectIsOnKbCategoriesPage(state)
  const onKbCategoryCreatePage = selectIsOnKbCategoryCreatePage(state)
  const onKbCategoryEditPage = selectIsOnKbCategoryEditPage(state)
  const onKbSettingsPage = selectIsOnKbSettingsPage(state)
  const ticketTitle = selectCurrentTicketTitle(state)
  const folderTitle = selectCurrentFolderName(state)
  const searchTitle = selectCurrentTicketSearchQueryTitle(state)
  const searchQuery = selectCurrentTicketSearchQueryString(state)
  const ticketsCount = selectCurrentTicketSearchResultTotalCount(state)
  const path = selectCurrentPath(state)
  const onReportsInboxConversationsPage = selectIsOnConversationsPage(state)
  const onReportsInboxProductivityPage = selectIsOnProductivityPage(state)
  const onReportsInboxAgentOverviewPage = selectIsOnAgentOverviewPage(state)
  const reportsInboxAgentName = selectAgentOverviewName(state)
  const onReportsInboxTeamOverviewPage = selectIsOnTeamOverviewPage(state)
  const reportsInboxTeamName = selectTeamOverviewName(state)
  const onReportsInboxAgentsOverviewPage = selectIsOnAgentsOverviewPage(state)
  const onReportsInboxTeamsOverviewPage = selectIsOnTeamsOverviewPage(state)
  const onReportsInboxHappinessPage = selectIsOnHappinessPage(state)
  const onReportsKbArticlesPage = selectIsOnArticlesPage(state)
  const onOnboardingPage = selectIsOnOnboardingPage(state)
  const onOauthErrorPage = selectIsOnOauthErrorPage(state)
  const onInboxEmptyPage =
    selectShowInboxesEmptyPage(state) && selectIsInInbox(state)
  const isOnLoginPage = selectIsOnLoginPage(state)
  const isOnAIAgentPage = selectIsOnAIAgentPage(state)

  if (path.match('settings')) return 'Settings'
  if (isOnLoginPage) return null
  if (onOauthErrorPage) return 'Error'

  if (onNewConversationPage) return 'Log Conversation'

  if (onNewEmailPage) return 'New Conversation'

  if (isOnAIAgentPage) return 'AI Agent'

  // Onboarding
  if (onOnboardingPage) return 'Sign Up'

  // Reports
  if (onReportsMainPage) return 'Reports'
  if (onReportsInboxConversationsPage) return 'Conversation report'
  if (onReportsInboxProductivityPage) return 'Productivity report'
  if (onReportsInboxAgentOverviewPage)
    return `${reportsInboxAgentName} productivity`
  if (onReportsInboxTeamOverviewPage)
    return `${reportsInboxTeamName} productivity`
  if (onReportsInboxAgentsOverviewPage) return `${app.t('Agents')} overview`
  if (onReportsInboxTeamsOverviewPage) return `${app.t('Team')} overview`
  if (onReportsInboxHappinessPage) return 'Happiness report'
  if (onReportsKbArticlesPage) return 'Article performance'

  // KB
  if (onKbCreatePage) return 'Create knowledge Base'
  if (onKbDashboardPage) return 'Dashboard'
  if (onKbThemePage) return 'Edit Theme'
  if (onKbArticlesPage) return `${capitalize(articlesState)} Articles`
  if (onKbArticleCreatePage) return 'New Article'
  if (onKbArticleEditPage) return 'Edit Article'
  if (onKbCategoriesPage) return `${capitalize(categoriesState)} Categories`
  if (onKbCategoryCreatePage) return 'New Category'
  if (onKbCategoryEditPage) return 'Edit Category'
  if (onKbSettingsPage) return 'Knowledge Base Settings'
  if (onInboxEmptyPage) return 'Welcome to Groove'

  if (ticketTitle) return ticketTitle

  const titleWithCount = folderTitle || searchTitle || searchQuery
  if (titleWithCount && (ticketsCount || ticketsCount === 0))
    return titleWithCount

  return 'Loading...'
}

export const selectGlobalCount = state => {
  const folderTitle = selectCurrentFolderName(state)
  const searchTitle = selectCurrentTicketSearchQueryTitle(state)
  const searchQuery = selectCurrentTicketSearchQueryString(state)
  const ticketsCount = selectCurrentTicketSearchResultTotalCount(state)
  const showEmptyInboxesPage = selectShowInboxesEmptyPage(state)

  if (showEmptyInboxesPage) return null

  const titleWithCount = folderTitle || searchTitle || searchQuery
  if (titleWithCount && (ticketsCount || ticketsCount === 0))
    return ticketsCount

  return null
}
